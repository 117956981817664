.cat-home1{
width: 100%;
display: flex;
justify-content: center;
margin-top: 50px;

padding-bottom: 150px;
}
.cat-home2{
    width: 80%;

}
.cat-ul{
list-style: none;
display: flex;
gap: 20px;
}
.cat-li{

    color: #929292;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
}
.cat-li:hover{

    color: black;
    
}
.cat-df-1{
    display: flex;
    align-self: center;
    justify-content: space-between;
}
.cat-home-h1{
    color: black;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.card-s-cat{

}
.card-cat-1 {
    /* width: 270px; */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 0;
}
.card-cat-img-1{
    width: 100%;
    border-radius: 5px;
}

.pro-name-cat {
    font-weight: 500;
    font-size: 18px;
}
.pro-cat-1{
    font-size: 12px;
    color:black;
    font-weight: 500;

}
.pro-av-color{
    font-weight: 500;

    font-size: 16px;
    color: #999;

}
.pro-mrp-cat{
    font-size: 12px;
    font-weight: 400;

}
.cat-det-cont{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.cards-section-cat-home{
    padding: 30px 0px;


    flex-wrap: wrap;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}
.cards-section-cat-home1{
    padding: 30px 0px;


    flex-wrap: wrap;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}




@media (max-width:1120px) {
    .cards-section-cat-home {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
    .cards-section-cat-home1 {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
    .category-ske-section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr)) !important;
    }
    .card-ske-s {
        width: 100% !important;
        height: 170px !important;
    }
}



@media (max-width:614px) {
    .cards-section-cat-home {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }
    .cards-section-cat-home1 {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }

    .category-ske-section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)) !important;

    }
}






  @media (max-width: 515px) {
 
    .pro-name-cat {
        font-weight: 500;
        font-size: 15px;
    }
    .pro-mrp-cat {
        font-size: 15px;
        font-weight: 400;
    }
    .cat-home2 {
        width: 90%;
    }
    .pro-cat-1 {
        font-size: 14px;

    }
    .pro-av-color {

        font-size: 14px;

    }
  }


  @media (max-width: 350px) {
    .cards-section-cat-home {
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }
    .card-ske-s {
        width: 100% !important;
        height: 230px !important;
    }
    .cards-section-cat-home1 {
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }
    .category-ske-section {
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;

    }

  }

.card-cat-re{
    position: relative;
}
.card-car-ab-1 {
    position: absolute;
    bottom: 55px;

    width: 100%;
  z-index: 10;
    justify-content: space-around;
}
.add-card-btn-1{

    background: black;
    color: white;
    padding: 8px;
    border-radius: 5px;
    width: 20%;
}

.card-select-size >select {
    padding: 8px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid gray;
    width: 100%;
    cursor: pointer;
}
.card-select-size{
    width: 50%;
}
.card-car-ab-1{
display: none;
/* display: flex; */

}
.card-rel-c1:hover .card-car-ab-1{
    /* display: flex; */

}
.card-rel-c1{
    position: relative;
}


@media (max-width:800px) {
    .card-car-ab-1{
        display: none !important;
    }
}
.category-ske-section {
    padding: 30px 0px;


    flex-wrap: wrap;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}
.category-ske-load {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.category-ske-det{
display: flex;
flex-direction: column;
gap: 5px;

}
.category-ske-img{


}

.category-ske-img>img{
width: 100%;
border-radius: 5px;
}
.category-ske-det>h1{

    width: 80px;
    background: #00000029;
    border-radius: 2px;
    height: 10px;
}
.category-ske-det>h2{

    width: 60px;
    background: #00000029;
    border-radius: 2px;
    height: 10px;
}

.card-ske-s{
    width: 100% !important;
    height: 250px;
}