.carousel-home {
}
.img-carousel-home {
  width: 100%;
  object-fit: cover;
  height: 70vh;
  background-color: #f3f3f3;
}
.carousel-home {
  width: 100%;
}
.custom-pagination {
}
.swiper-wrapper {
  z-index: 10 !important;
}
.carousel-h-d2 {
}
.carousel-h-d3 {
  display: flex;
  align-items: center;
  width: 85%;
  position: relative;
  margin-top: 80px;
}
.carousel-h-d2 {
  display: flex;
  justify-content: center;
}
.btn-swiper-home {
  cursor: pointer;
  font-family: "Catamaran", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
button {
  background: transparent;
  border: none;
}
.btns-paginations-home {
  /* width: 5%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* z-index: 10; */
  position: absolute;
  left: -35px;
}
.pag-btn-s1 {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.pav-span-home {
  background: linear-gradient(to right, black 50%, white 50%);
  border: 1px solid black;
  border-radius: 3px;
  width: 80px;
  height: 1px;
  display: block;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.pag-btn-s1 > button,
.pav-span-home {
  color: #999;
}

.pag-btn-s1:hover .btn-swiper-home {
  color: black;
  z-index: 10;
}

.pag-btn-s1:hover > .pav-span-home {
  color: black;
  z-index: 10;
}

.pag-btn-s1:hover > .line-b-carousel {
  background: linear-gradient(to right, black 50%, white 50%);
  border: 1px solid black;
  border-radius: 3px;
  width: 100px;
  height: 1px;
  display: block;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.pag-btn-s2 .btn-swiper-home {
  color: black;

  z-index: 10;
}
.pag-btn-s2 .pav-span-home {
  color: black;
  z-index: 10;
  transition: all 0.5s ease-out;
}

.pag-btn-s2 {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.line-b-carousel {
  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border: 1px solid black;
  border-radius: 3px;
  width: 150px;
  height: 1px;
  display: block;
  transition: all 0.5s ease-out;
}
.pag-btn-s1:hover > .pav-span-home {
  background-position: left bottom;
  z-index: 10;
}
.pag-btn-s2:hover > .line-b-carousel {
}

.content-swip-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.png-1-homec {
  /* top: 0px;
  right: 0px;
  position: absolute; */
  width: 400px;
  border-radius: 5px;
}

@media (max-width: 980px) {
  .png-1-homec {
    width: 250px;
  }
}

@media (max-width: 770px) {
  .swip-home-h1 {
    font-size: 18px;
  }
  .content-swip-2>p{
    font-size: 12px;
  }
  .img-carousel-home {
   
    height: 35vh;
  }
  .content-head-swip1 {

    padding: 15px;
}
.pav-span-home {

  width: 50px;
}


}
@media (max-width: 550px) {
  .carousel-h-d3 {

    width: 95%;
  }
  .btns-paginations-home{
    display: none;
  }

}

@media (max-width: 500px) {
  .png-1-homec {
    width: 181px;
}

.content-head-swip1 {
  display: flex;
  align-items: center !important;
  justify-content: space-around;
  /* margin-top: 50px; */
  width: 100%;
  flex-direction: column-reverse;
}
.content-swip-2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}
}




.content-swip-2 {
  display: flex;
  flex-direction:  column;
gap: 5px ;
}
.content-head-swip1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  /* margin-top: 50px; */

  width: 100%;
}

.fadeInUp1 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight1 {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
