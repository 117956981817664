.cart-d1 {
    margin-top: 60px;
    padding: 10px 0px;
    display: flex;
    background: #fafafa;
    justify-content: center;
}
.cart-d2{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
}
.shop-bag-h1{
    text-align: center;
    font-size: 30px;
    padding: 10px 0px;
}

.cart-product-img{
    width: 100px;
    height: 100px;

    border-radius: 5px;
}
.left-h-cart{
    width: 80%;
    display: flex;
    gap: 10px;
}
.cart-pro-det{

}
.right-h-cart{
    width: 10%;
    display: flex;
    justify-content: center;
}
.pro-name-cart{

    font-size: 15px;
    font-weight: 600;
}
.pro-price-cart{

    font-size: 15px;
    font-weight: 400;
}

.cart-d3{
    display: flex;
    justify-content: space-between;

    padding: 20px;
  background: white;
  /* width:600px; */
    border-radius: 5px;

    }


    .cart-card-inner-det{
        display: flex;
     
    }
    .cart-pro-det2{
        display: flex;
        justify-content: space-between;
        /* flex-direction: column; */
        gap: 5px;
        flex-wrap: wrap;
    }
    .car-pro-det1{
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .cart-card-inner-det>span{
font-size: 13px;
    }
    .qty-sele{
font-size: 13px;

    }
    .cart-lh-rh-section{
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
    }
    .cart-d4{
      
 
        padding: 20px;
        background: white;
        border-radius: 5px;
    }
    .rh-cart-t1{
        display: flex;
    justify-content: space-between;
    align-items: center;
    }
    .rh-cart-t1>span:nth-child(1){
        color: #999;
    font-size: 13px;

    }
    .rh-cart-t1>span:nth-child(2){
        color: black;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;

    }
    .rh-cart-t2 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-bottom: 1px solid #999;
        padding: 0px 0px 10px 0px;
    }

    .rh-cart-t2>button{
    background-color:white;
    padding: 12px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    }
    .cart-d5{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .rh-cart-t2>span{
        font-size: 12px;
    }

    .rh-cart-t4{
        display: flex;
    justify-content: space-between;
    align-items: center;
    }
    .rh-cart-t4>span
{
    color: #6d6b6b;
    font-size: 13px;

    }
    .rh-cart-t5{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid;
        padding-top: 10px;
    }
    .rh-cart-t5>span{

        color: black;
        font-size: 15px;
        font-weight: bold;

    }
    .rh-cart-t6>button{

        
        background-color: black;
        padding: 15px;
    color: white;
    border-radius: 5px;
    width: 100%;
    }
    .rh-cart-t7>button{

        cursor: not-allowed;
        background-color: gray;
        padding: 15px;
    color: white;
    border-radius: 5px;
    width: 100%;
    }

    .rh-tt-span{

        color: black;
        font-size: 13px;
    }
    .rh-cart-t7{

    }
    .rh-we-acc-icons>span{
font-size: 10px;
    }
    .rh-we-acc-icons{
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }
    .we-acc-ions>img{
        width: 35px;
    }


    .cart-d31{
        width: 600px;
    }

    .cart-d41 {
        width: 350px;



        
    }


@media (max-width:1000px) {
    .cart-d2 {
        width: 95%;

    }
}
@media (max-width:800px) {
    .cart-lh-rh-section {
      display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .cart-d31{
        width:100%;
    }
    .cart-d41 {
        width:100%;



        
    }
    .cart-d2 {
        gap: 8px;
    }
}


.ReactModal__Overlay {
    position: fixed;
    inset: 0px;
    background-color: #000000a8 !important;
    /* background: rgba(0, 0, 0, 0.5) !important; */
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: 1000;
}

.modal-cart-coupon{
    width: 300px;
}
.coupon-section {
    display: flex;
    flex-direction: column;
}
.close-m2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.coupon-section-modal{
    display: flex;
    justify-content: space-between;
    align-items: center;
}





.lable-ccu{
    font-size: 13px;
}


.input-field-coupon{

    line-height: 1.25;
    font-size: 1rem;

    font-weight: 400;
    text-transform: none;
    border: 1px solid;
    border-radius: 0;
    border-color: #d0d0d0;
box-sizing: border-box;

padding: 8px;
width: 100%;
}


.add-coupon{

    background: black;
    padding: 10px;
    color: white;
    align-items: center;
    flex: 0 0 40%;
    line-height: 1;
    max-height: 48px;
    min-width: auto;

}



.add-coupon2{
    cursor: not-allowed;
    background-color: gray;

    padding: 10px;
    color: white;
    align-items: center;
    flex: 0 0 40%;
    line-height: 1;
    max-height: 48px;
    min-width: auto;

}
.apply-coupon-section{
    display: flex;
    gap: 10px;
}
.para-c-m{
    font-size: 16px;
}
.coupon-mt-sec{
    margin-top: 20px;
}


@media (max-width:500px) {
    .modal-cart-coupon {
        width: 100%;
    }
}
@media (max-width:388px) {
    .left-h-cart {
    
        flex-direction: column;
    }
    .car-pro-det1 {
        width: calc(100% );
   
    }

    .cart-d3 {
 
        position: relative;
    }
    .right-h-cart {
        position: absolute;
        right: 10px;
    }
    .cart-d3 {
     
    }
    .left-h-cart {
        width: 100%;
 
    }
    
}
.empty-bag{

    text-align: center;
    font-size: 30px;
    
}

@media (max-width:500px) {
    .empty-bag{

        text-align: center;
        font-size: 20px !important;
        
    }
}

