.pro-list-p1{
    background: #fafafa;
    padding: 40px 0px;
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.pro-list-p2{
    width: 80%;
}

.pro-list-head{
text-transform: uppercase;
    font-size: 25px;
}
.pro-list-p4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}