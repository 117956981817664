
*{
  margin: 0;
  padding: 0;
  /* font-family: Catamaran,sans-serif; */

  font-family: "HM Sans Regular";
}

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

@import url(https://db.onlinewebfonts.com/c/70f3e46b3bb904260d1b1a6e0f2edf08?family=HM+Sans+Regular);

ul{
  list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.p-tag-otp>span{
  text-decoration: underline;
  cursor: pointer;
}

.loader-sp {
  background: white;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

select{
  color: black;
}