.chekout-d1 {
    margin-top: 60px;
    padding: 10px 0px;
    background: #fafafa;

}
.check-heading{


    text-align: center;
    font-size: 30px;
    padding: 10px 0px;
}
.checkout-d2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.checkout-d3 {
    width: 80%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
}
.lh-checkout{
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.rh-checkout{
    
}
.bill-dd-h1{
    font-size: 15px;
    font-weight: 600;
}
.lh-check-2 {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
}
.lh-c1 {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
}



.lh-check-3>h1 {
    font-size: 15px;
    font-weight: 600;
}
.lh-check-3{
    display: flex;
    justify-content: space-between;
}
.lh-check-4 {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}
.lh-check-4>span{
    font-size: 13px;
}
.lh-c2{

}
.lh-c3>span{
    font-size: 12px;
}
.lh-c4 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.lh-c5{
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.lh-c5>span{
    line-height: 1.2307692308;
    font-size: .8125rem;
    font-weight: 400;
}
.lh-form-in {
    gap: 5px;
    display: flex;
    flex-direction: column;
}
.form-group-lh-checkout{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input:focus{
    outline: none;
}
select:focus{
    outline: none;
}
.lh-form-in >input{
    padding: 11px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid gray;
}
.lh-form-in >label{
    font-size: 14px;

}
.lh-c7 {
    display: flex;
    justify-content: space-between;
}
.ih-c8{
    width: 48%;
}

.lh-form-in >select {
    padding: 11px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid gray;
}

.lh-c8{
    display: flex;
    justify-content: center;
}
.lh-c8 >button{
    background: black;
    padding: 10px 10px;
    color: white;
    width: 50%;
    border-radius: 5px;
}
.rh-c-p1{
    font-size: 12px
}
.rh-c-p1>span{
text-decoration: underline;
cursor: pointer;
}
.rh-checkout-t6>button {
    background-color: black;
    padding: 15px;
    color: white;
    border-radius: 5px;
    width: 100%;
}

@media(max-width:1000px){

    /* .checkout-d3 {
        width: 95%;
        display: flex;
        gap: 10px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .lh-checkout {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    } */
    .checkout-d3 {
        width: 95%;

    }
}
@media(max-width:870px){



    .checkout-d3 {

        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .lh-checkout {
        width: 100%;
    }
    .check-d4 {
        width: auto !important;

    }
    .rh-checkout {
        width: 100%;
    }
}

.check-d4 {
    width: 350px;
    padding: 20px;
    background: white;
    border-radius: 5px;
}

.rh-checkout-t67>button{
    background-color:gray;
    padding: 15px;
    color: white;
    border-radius: 5px;
    width: 100%;
    cursor :not-allowed;
}