.pro-view1 {
}
.pro-view2 {
  width: 100%;
  /* margin-top: 80px; */
  display: flex;

  background: #fafafa;
  justify-content: center;
  padding: 80px 0px;
}

.pre-view3 {
  width: 70%;
}
.pre-view-c1 {
  display: flex;
  gap: 5px;
  width: fit-content;

  color: #9b9b9b;
}
.pre-view-c1 > :nth-child(1) {
  color: #9b9b9b;

  cursor: pointer;
}

/* src/Carousel.css */
.carousel-pro-v {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: column;
}

.photo {
  cursor: pointer;
}

.photo-pro-carousel img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.lh-sket-pv{
  width: 100px !important;
  height: 100px !important;

}


.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.full-screen img {
  cursor: pointer;
  width: 100%;
  height: 500px;
  border-radius: 5px;
}

.pro-carousel-section{
    display: flex;
    justify-content: center;
    gap: 5px;

}
.pro-full-c-img{

}
.image-controls{

  display: flex;
  justify-content: space-between;
  align-items: center;
}
svg{
  cursor: pointer;
}
.photo-pro-carousel>img{
  cursor: pointer;
border-radius: 5px;
}
.img-1-carosuel>img{
width: 100%;
cursor: pointer;
border-radius: 5px;

}
.img-1-carosuel{
  width: 500px;
  height: 500px;
  
  }



  .image-number{
    color: #929292;
    font-size: 20px;
  }
  .pro-r-side-content{
width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  .pro-view4{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}
  .pro-r-h1{
    font-weight: 500;
    font-size: 20px;
  }
  .pro-r-h2{
    font-weight: 500;
    font-size: 18px;
  }
  .pro-r-h3{
    font-weight: 500;
    font-size: 14px;
  }

  .pro-details-head{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .description-pro-section{
    color: #999;
  }

  .size-pro-v {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  .size-pro-v1>span {
    font-weight: 500;
    font-size: 18px;
}

  .size-pro-v1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.size-pro-v1 :nth-child(2) {
  color: #999;
  cursor: pointer;
}


.color-select-pro-v {
  border: 1px solid #999;
  padding: 15px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #999;

}

.color-select-pro-v:hover {

  border: 1px solid  black;

}


.size-select-pro-v {
  border: 1px solid #999;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  color: black;

}

.size-select-pro-v:hover {

  border: 1px solid  black;

}




.size-btn-pro-v1{

  display: flex;
  gap: 6px;
  align-items: center;
}
button{
  cursor: pointer;
}

.buy-add-btns-pro-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
 
}
.buy-add-btns-pro-1 :nth-child(1) {

  background: black;
  width: 100%;
  padding: 15px;
  color: white;
  border-radius: 5px;
  font-size: 16px;
}

.buy-add-btns-pro-1 :nth-child(2) {

  background: transparent;
  width: 100%;
  padding: 15px;
  color: black;
  border-radius: 5px;
  border: 1px solid #999;
  font-size: 16px;
}



@media(max-width : 1500px){
  .pre-view3 {
    width: 90%;
}
}
@media(max-width : 1190px){
  .pre-view3 {
    width: 95%;
}
}

@media(max-width : 1100px){

  .img-1-carosuel {
    width: 370px;
    height: 453px;
  }
  .pro-view4 {
 
    justify-content: space-between;

}
}




@media(max-width : 930px){
  .pro-r-side-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pro-view4 {

  flex-direction: column;
  align-items: center;
}
.img-1-carosuel {
  width: 462px;
  height: 468px;
}
}

@media(max-width : 650px){
  .rh-pro-view-sec{
    width: 100%;
  
  }
  .pro-r-side-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* .pro-r-side-content {

  width: 400px;

} */
.pro-carousel-section {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column-reverse;
  align-items: center;
}
.carousel-pro-v {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.pro-view4 {

  gap: 25px;
}


.img-1-carosuel {
  width: 358px;
  max-height: 370px;
  /* height: 371px; */
}
.full-screen img {
max-height: 500px;
height: unset;
}
}

@media(max-width : 370px){
  /* .pro-r-side-content {

    width: 400px;
  
  } */


.img-1-carosuel {
  width: 283px;
  max-height: 300px;
  /* height: 371px; */
}
}
@media(max-width : 350px){

  .photo-pro-carousel img {
    width: 80px;
    height: 76px;
    object-fit: cover;
  }
  .lh-sket-pv{
    width: 80px !important;
    height: 76px !important;
  
  }
  }




  .del-sec1 {
    display: flex;
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
    padding: 10px 0px;
    color: black;

    flex-direction: column;
}
.del-sec1 >span{

  font-weight: 500;
  font-size: 18px;
}
.del-sec-2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.del-sec1>p{
  padding: 20px 0px;

}

.del-sec3 {
  display: flex;
  border-bottom: 1px solid #999;

  padding: 10px 0px;
  color: black;

  flex-direction: column;
}
.del-sec3 >span{

font-weight: 500;
font-size: 18px;
}
.del-sec3>p{
  padding: 20px 0px;

}

.related-section-main{
  display: flex;
  justify-content: flex-start;
  gap: 10px;

}
.related-h1{

  color: #999;
  font-weight: 500;
  padding: 15px 0px;
  font-size: 15px;
}
.related-section-head{
text-transform: uppercase;
  padding: 80px 0px 50px 0px;
}
.related-con-c1>h1{
  font-weight: 500;
  font-size: 14px;
}
.related-con-c1>h1:hover{
  text-decoration: underline;
  cursor: pointer;

}
.related-con-c1>h2{
  font-weight: 500;
  font-size: 13px;
}
.related-card>img{
  width: 280px;

  /* max-height: 364px; */
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}
.related-card {
  width: 280px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 10px;
}

.related-card-img{
  width: 300px;

}
.related-content-d1{
  width: 300px;

}
.related-con-c1{
  display: flex;
    flex-direction: column;
    /* gap: 10px; */
}
.size-select-pro-v2{
  border: 1px solid black;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  color: black;
}

.skeleton{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.skeleton-img{
  width: 100px;
  height: 100px;
border-radius: 5px;
background-color: #8080804a;
}

.img-1-carosuel22 {
border-radius: 5px;

  width: 500px;
  height: 500px;
background-color: #8080804a;

}

.skeleton-1-p1{
  width: 100%;
  height: 100%;
}

