.fav-head{
    text-align: center;
    font-size: 30px;
    padding: 10px 0px;
}


.fav-head2{
    text-align: end;
    font-size: 14px;
    padding: 10px 0px;
    font-weight: 100;
}

.fav-f1 {
    background: #fafafa;
    padding: 40px 0px;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}
.fav-f2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-content: center;
}
.fav-card-img{
    width: 100%;
    border-radius: 5px;

}
.fav-card {
    /* width: 200px; */
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
}
.ProductDetailPart{
    
    position: relative;
}
.fav-card-p-name{

    font-size: 13px;
    font-weight: 100;
    cursor: pointer;
}
.fav-card-p-name:hover{
text-decoration: underline;

}
.fav-card-p-name2{
    font-size: 13px;
    font-weight: 100;
}
.fav-card-btn{
    background: black;
    width: 100%;
    color: white;
    padding: 6px; 
    border-radius: 5px;
}
.fav-f3 {
    /* display: flex;
    justify-content: space-between; */
    flex-wrap: wrap;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}
@media (max-width:530px) {
    .fav-f2 {
        width: 95%;
 
    }
}
@media (max-width:1120px) {
    .fav-f3 {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
}



@media (max-width:614px) {
    .fav-f3 {
        flex-wrap: wrap;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }
}

.fav-card-ff1{
    /* flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(25% - 30px);
    margin: 0 15px 30px; */
    position: relative;

}
.ProductDetailPartIcon{
    position: absolute;
    top: 5px ;
    right: 5px;
    z-index: 20;
}
.wish-empty-d{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
}
.wish-empty-d>h1{
    font-size: 15px;
}
.wish-empty-d>p{
    text-align: center;
    font-size: 14px;
}
.wish-emp-btn{
background-color: black;
    padding: 10px;
    border-radius: 5px;
    color: white;
}