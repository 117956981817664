.footer-bg{
    border-bottom: 0.2px solid #9b9b9b4d;
    padding: 100px;
    border-top: 0.2px solid #9b9b9b4d;
}
.head-footer{
    padding:10px 0px; 
    background: white;

}
.footer-logo{
    width: 200px;
}
.circle-blur3 {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;

    background: #f45162;
    filter: blur(100px);
}
.circle-blur4 {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    right: 0;
    background: #d14add;
    filter: blur(100px);
}
.logo-footer{
    width: 120px;
}
.footer-para{

    color: #9b9b9b;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8;
    margin: 0;
    opacity: 0.
}
.flex-footer-1 {
    display: flex;
    justify-content: space-around;
}
.left-h-footer {
    display: flex;
    flex-direction: column;
    /* padding: 50px; */
    width: 500px;
}

.footer-h1{

    color: black;
    font-size: 25px;
}
.ul-footer>a{
cursor: pointer;
color: #9b9b9b;
}

.ul-footer{
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 11px;

}
/* .email-li{
    color: lightgray !important;
} */
.icon-footer{
    color: black;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 26px;
    cursor: pointer;
    background: #1e1f21;
  transition: border-color 0.3s ease;


}
.icon-footer:hover{
    border: 1px solid;
    border-image-slice: 1;

    border-color: #17c0e9;
}
.div-footer-icons{
    display: flex;
    gap: 10px;
}
@media only screen and (max-width: 1230px) {
    .flex-footer-1 {
     
  
      flex-wrap: wrap;
      flex-direction: column;
  }
  .footer-bg {
  
    padding: 50px !important;
  }
  
  
  }
@media only screen and (max-width: 650px) {


  .footer-bg {
    padding: 20px !important;
  }
  .left-h-footer {
width: unset !important;
  }

}
@media only screen and (max-width: 650px) {



.ul-footer>li{
    cursor: pointer;
    color: #9b9b9b;
    font-size: 12px;

    }

    .footer-para {
        color: #9b9b9b;
        font-size: 12px;

    }

}
.ul-footer>li{
    cursor: pointer;
    color: #9b9b9b;
    }

.text-copyright{

    
    color: #9b9b9b;
    font-size: 16px;
    font-weight: 500;

    text-align: center;
    padding: 35px 0px;
}


.footer-logo{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    padding: 10px 0px;
}