.contact-c1{
width: 100%;
display: flex;
justify-content: center;
padding: 130px 0px;
}
.contact-c2{
    width: 80%;
}
.contact-d4{

    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 20px;
    
}
.contact-span-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-span-section >span:nth-child(1){
    font-weight: bold;
    font-size: 15px;

}
.contact-span-section >span{

    font-size: 15px;

}

.contact-para{
    text-align: center;
    font-size: 15px;
}

.contact-c3{

    display: flex;
    flex-direction: column;
    gap: 20px;
}