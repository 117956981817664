* {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
.bg-conn {
margin-top: 60px;

}
.bg-conn>h1{
  color: black;
text-align: center;
padding: 10px 0px;
font-size: 20px;

}
.ngf-com1>h1{
  color: black;
  font-size: 18px;
  font-weight: 300;

}
.ngf-com1>p{
  color: white;
  font-size: 19px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #999;
}
.ngh-1{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 26px 0px;
  
}
.ngf-com1{
  width: 72%;
  display: flex;
flex-direction: column;
gap: 20px;
}