.profilepic {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #111;
    cursor: pointer;
  }
  

  
  .profilepic__image {
    object-fit: cover;
    opacity: 1;
    transition: opacity .2s ease-in-out;
  }
  
  .profilepic__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }
  
  .profilepic__icon {
    color: black;
    padding-bottom: 8px;
  }
  
  .fas {
    font-size: 20px;
  }
  
  .profilepic__text {
    text-transform: uppercase;
    font-size: 12px;
    width: 50%;
    text-align: center;
  }


  .Profile{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
  }


  .ProfileMiddleName{
    color: black;
    margin-top: 5px;
  }


  .ProfileMiddle{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .lh-profile{
    width: 400px;
    padding: 20px;
    background: white;
    border-radius: 5px;
  }
  
  .rh-profile{
    width: 600px;
    padding: 20px;
    background: white;
    border-radius: 5px;
  }
  .main-d-profile{
    margin-top: 56px;
    background: #fafafa;
    padding: 40px 0px;
  }
  .pro-d1{
display: flex;
justify-content: center
  }
  .pro-d2{
    width: 80%;
  }
  .lh-d1-pro{
    display: flex;
    justify-content: space-between;

    border-top: 1px solid gray;
    padding: 15px 0px;
    cursor: pointer;

    color: black;
  }
  .lh-d1-pro1{
    display: flex;
    justify-content: space-between;
  

    padding: 15px 0px;
    cursor: pointer;

    color: black;
  }
  .lh-pro-h1{

    font-size: 15px;
    font-weight: 100;
  }

  .pro-c-1 {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
}

.rh-d-5 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.rh-d-2{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.rh-d-2>svg{

cursor: pointer;
}

.upp-ch{
  padding: 10px 0px;
}
.add-details-pro>h1{
  color: black;
  font-size: 15px;
  font-weight: 100;
}
.add-details-pro{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.details-add-lf{
  padding: 10px 0px;
}

.acc-btn-sa{
  background:  black;
  border: none;
  padding: 12px 25px;
  border-radius: 5px;
  color: white;
  margin-top: 20px;
}
.lh-cont-pro{
  display: flex;
  justify-content: space-between;
}

@media (max-width:1400px){
  .pro-d2 {
    width: 95%;
}
.pro-c-1 {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;

}
.rh-profile {

  width: 437px;
}
}

.log-no-uss {
  color: black;

  font-weight: 300;
  font-size: 27px;

}
.acc-no-use1{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.btn-sign-in-pro{

  
  padding: 10px 31px;
  border: none;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}

.my-or-dt{
  font-size: 15px;
  font-weight: 100;
}

.rh-d-d2{
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
}
.order-d-d2{
  color: black;
}
.order-d-dd2 {
  gap: 40px;
  display: flex;
}

.order-d-dd3>h1{
  font-size: 13px;
  font-weight: 100; 
  gap: 5px;
}
.order-d-dd4{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.order-d-dd4>h1{
  font-size: 13px;
  font-weight: 100; 
}
.order-d-dd1 {
  display: flex;
  justify-content: space-between;
  padding: 10px ;
border-bottom: 1px solid  gray;

}
.order-box1{

  border: 1px solid  gray;
  border-radius: 5px;
}


.order-box-rh{

}
.order-d-dd3{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.order-box-h1 {
  font-size: 12px;
  font-weight: 600;
}
.order-box-h2{
  font-size: 13px;
  font-weight: 500;
}

.order-box-lh{
  color: black;
  display: flex;
  gap: 10px;
}

.order-box-img-1{
  width: 66px;
  border-radius: 5px;
}

.order-box-d1{
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.order-box-rh>button{
  border: none;
  background:  black;
  padding: 8px;
  border-radius: 5px;

  color:white;
}
.order-lh-prod{

}

.order-map-fun {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}



@media (max-width:550px){

  .order-box-d1 {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.order-d-dd1 {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid  black;

  gap: 10px;
  flex-direction: column;
}
.order-d-dd4 {
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  gap: 5px;
}
.order-d-dd2 {
  gap: 40px;
  display: flex;
  justify-content: space-between;
}
}












.check-uppr{
    display: flex;
    align-items: center;
}
.check-h1{
    color: #999;
    font-size: 15px;
    font-weight: 100;
    cursor: pointer;
    cursor: pointer;


}
.check-h2{

    
    color:  black;
    font-size: 15px;
    font-weight: 100;
}
.check-out-head1{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.check-out-head{
    width: 95%;
}

/* App.css */

  .form-container {
    /* max-width: 400px;
    margin: 0 auto; */
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  
  .check-label {
    display: block;
    margin-bottom: 5px;
    color: black;
    font-size: 14px;
  }
  
  .input-check{
    width: 100%;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
.check-out-left-h{

width: 40%;
}
.check-out-right-h{

    width: 40%;



}

.check-form-d1{
    display: flex;
    gap: 10px;
}
.left-h-h1{
    align-items: center;
    font-style: italic;
    color:  black;
    font-family: "Zuume";
}
.left-head-c{
    padding: 20px 0px;
}
.check-out-head-dv{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}
.left-bb-check{
    border: 2px solid  black;
    border-radius: 5px;

    padding: 10px;
}
.rh-check-con1{
    display: flex;
    align-items: center;
     justify-content: space-between;
      border-bottom: 1px solid gray;
      padding: 10px 0px;
}


.add-details-rh{
  font-size: 15px;
  font-weight: 600;
}