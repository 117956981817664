.login-d1{
    background: #fafafa;
    padding: 40px 0px;
    margin-top: 60px;
}
.login-d2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.login-d3{
    width: 80%;
}

.log-my-h1{

    text-align: center;
    font-size: 30px;
    padding: 10px 0px;
}
.login-d4>h1{
    text-align: center;
    font-size: 18px;

}
.login-d4 {
    padding: 10px 0px;
    border-bottom: 2px solid black;
}
.login-d7 {
    display: flex;
    justify-content: center;
    width: 450px;
    flex-direction: column;

}
.login-d8 {
    display: flex;
    justify-content: center;
}
.login-d6{
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.get-otp-btn>button{
    width: 100%;
    background: gray;
    padding: 10px;
    color: white;
    border-radius: 5px;

}
.get-otp-btn2>button{
    width: 100%;
    background: black;


    padding: 10px;
    color: white;
    border-radius: 5px;
}

.p-tag-otp{

    font-size: 15px;
}


@media (max-width:400px) {
    .login-d7 {
   
        width: 95%;
  
    
    }
}
.p-tag-otp2{
    font-size: 12px;
}
.p-tag-opt3{
    font-size: 12px;

}
.log-btns {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.log-btns>button{

    background: black;
    padding: 5px 15px;
    border-radius: 5px;
    color: white;
}
