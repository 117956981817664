.nav-ul1 {
    display: flex;
    gap: 25px;
    align-items: center;
}
.nav-ul-li{
list-style: none;
font-size: 14px;

}
.nav-ul-li>a{
text-decoration: none;

}
.nav-ul-li>a:hover{
    text-decoration: underline;
    
    }
a{
    color: black;
text-decoration: none;

}
.logo-nav1>img{
    width: 100px;

}
.nav-main-d{
    display: flex;
    align-items: center;
}

.nav-d3{

}
.nav-d1{
  display: flex;
  gap: 10px;
}
    

.nav-main-d {
    display: flex;
    padding: 17px 0px;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    border-bottom: 0.2px solid #9b9b9b4d;
}
.d-f-nav{
    display: flex;
    align-items:  center;
}
@media (max-width: 600px) {
    .nav-main-d {
        display: flex;
        justify-content: space-around;
    }
    .d-none-nav-ul{
        display: none;
        
    }
    .link-navi-nav>span{

      display: none;

    }
    
    .menu-op-ic{
      display: flex !important;
    }
    .sv-cart-cc{
      display: flex !important;
      position: absolute;
      font-size: 11px;
      top: 4px;
    }


    .svg-nav1{
      font-size: 23px;
  
    }
    .svg-nav2{
      font-size: 20px;
  
    }
.link-navi-nav {

  
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

}
.nav-ul-li {
    display: flex;
    align-items: center;
    gap: 5px;
}
.link-navi-nav{
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}
.dropdown {
    position: relative;
    display: inline-block;
    border-radius: 5px;
    
  }
  
  /* Style the dropdown content (hidden by default) */
  .dropdown-content {
  
  
    border-radius: 5px;
    gap: 10px;
    padding: 10px;
    flex-direction: column;
  
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100  ;
    border-radius: 5px;
  }
  
  /* Style the dropdown links */
  .dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #333;
  }
  
  /* Change color on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  
  
  }
  
  /* Show the dropdown content when hovering over the container */
  .dropdown:hover .dropdown-content {
    display: flex;
    border-radius: 5px;
  }
  .NavBarMenuNavLinkDiv>p:hover{
text-decoration: underline;
  }
  .NavBarMenuNavLinkDiv>p{
font-size: 14px;
      }
  .nav-cat-spa{
    cursor: pointer;
    font-size: 13px;
  }

  .nav-dd1{
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .menu-op-ic{
    display: none;
  }


  
  .svg-nav1{
    font-size: 23px;

  }
  .svg-nav2{
    font-size: 20px;

  }
  .sv-cart-cc{
    display: none;
  }
  .close-icon-nav{
    display: flex;
    justify-content: flex-end;
  }
  .head-mobile-menu{
    padding: 20px;
  }

  .cat-di-img1{

    width: 45px;
    border-radius: 50%;
  }
  .cat-div-nav3>span{
    color: black;
  }
  .cat-div-nav3{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .cat-div-nav2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .category-div-nav{
    padding: 30px 0px;
  }
  .cat-secion-nav{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cat-div-nav4>a{
  color: black;
  cursor: pointer;
  text-align: center;
  }
  .cat-div-nav4{
    text-align: center;
    padding: 10px 0px;
  }

  .none-d-sidenav{
    display: none;
  }
  .side-menu-side{
  /* transition: left 0.5s ease-out; */

  }